const match_types = [
  {
    key: "6",
    text: "JO6",
    sport_id: "1",
  },
  {
    key: "7",
    text: "JO7",
    sport_id: "1",
  },
  {
    key: "8",
    text: "JO8",
    sport_id: "1",
  },
  {
    key: "9",
    text: "JO9",
    sport_id: "1",
  },
  {
    key: "10",
    text: "JO10",
    sport_id: "1",
  },
  {
    key: "11",
    text: "JO11",
    sport_id: "1",
  },
  {
    key: "12",
    text: "JO12",
    sport_id: "1",
  },
  {
    key: "13",
    text: "JO13",
    sport_id: "1",
  },
  {
    key: "14",
    text: "JO14",
    sport_id: "1",
  },
  {
    key: "15",
    text: "JO15",
    sport_id: "1",
  },
  {
    key: "16",
    text: "JO16",
    sport_id: "1",
  },
  {
    key: "17",
    text: "JO17",
    sport_id: "1",
  },
  {
    key: "18",
    text: "JO18+",
    sport_id: "1",
  },
  {
    key: "6",
    text: "6",
    sport_id: "2",
  },
  {
    key: "7",
    text: "7",
    sport_id: "2",
  },
  {
    key: "8",
    text: "8",
    sport_id: "2",
  },
  {
    key: "9",
    text: "9",
    sport_id: "2",
  },
  {
    key: "10",
    text: "10",
    sport_id: "2",
  },
  {
    key: "11",
    text: "11",
    sport_id: "2",
  },
  {
    key: "12",
    text: "12",
    sport_id: "2",
  },
  {
    key: "13",
    text: "13",
    sport_id: "2",
  },
  {
    key: "14",
    text: "14",
    sport_id: "2",
  },
  {
    key: "15",
    text: "15",
    sport_id: "2",
  },
  {
    key: "16",
    text: "16+",
    sport_id: "2",
  },
  {
    key: "6",
    text: "6",
    sport_id: "3",
  },
  {
    key: "7",
    text: "7",
    sport_id: "3",
  },
  {
    key: "8",
    text: "8",
    sport_id: "3",
  },
  {
    key: "9",
    text: "9",
    sport_id: "3",
  },
  {
    key: "10",
    text: "10",
    sport_id: "3",
  },
  {
    key: "11",
    text: "11",
    sport_id: "3",
  },
  {
    key: "12",
    text: "12",
    sport_id: "3",
  },
  {
    key: "13",
    text: "13",
    sport_id: "3",
  },
  {
    key: "14",
    text: "14",
    sport_id: "3",
  },
  {
    key: "15",
    text: "15",
    sport_id: "3",
  },
  {
    key: "16",
    text: "16",
    sport_id: "3",
  },
  {
    key: "17",
    text: "17",
    sport_id: "3",
  },
  {
    key: "18",
    text: "18+",
    sport_id: "3",
  },
  {
    key: "6",
    text: "6",
    sport_id: "4",
  },
  {
    key: "7",
    text: "7",
    sport_id: "4",
  },
  {
    key: "8",
    text: "8",
    sport_id: "4",
  },
  {
    key: "9",
    text: "9",
    sport_id: "4",
  },
  {
    key: "10",
    text: "10",
    sport_id: "4",
  },
  {
    key: "11",
    text: "11",
    sport_id: "4",
  },
  {
    key: "12",
    text: "12",
    sport_id: "4",
  },
  {
    key: "13",
    text: "13",
    sport_id: "4",
  },
  {
    key: "14",
    text: "14",
    sport_id: "4",
  },
  {
    key: "15",
    text: "15",
    sport_id: "4",
  },
  {
    key: "16",
    text: "16+",
    sport_id: "4",
  },

  {
    key: "6",
    text: "6",
    sport_id: "5",
  },
  {
    key: "7",
    text: "7",
    sport_id: "5",
  },
  {
    key: "8",
    text: "8",
    sport_id: "5",
  },
  {
    key: "9",
    text: "9",
    sport_id: "5",
  },
  {
    key: "10",
    text: "10",
    sport_id: "5",
  },
  {
    key: "11",
    text: "11",
    sport_id: "5",
  },
  {
    key: "12",
    text: "12",
    sport_id: "5",
  },
  {
    key: "13",
    text: "13",
    sport_id: "5",
  },
  {
    key: "14",
    text: "14",
    sport_id: "5",
  },
  {
    key: "15",
    text: "15",
    sport_id: "5",
  },
  {
    key: "16",
    text: "16+",
    sport_id: "5",
  },
  {
    key: "6",
    text: "6",
    sport_id: "6",
  },
  {
    key: "7",
    text: "7",
    sport_id: "6",
  },
  {
    key: "8",
    text: "8",
    sport_id: "6",
  },
  {
    key: "9",
    text: "9",
    sport_id: "6",
  },
  {
    key: "10",
    text: "10",
    sport_id: "6",
  },
  {
    key: "11",
    text: "11",
    sport_id: "6",
  },
  {
    key: "12",
    text: "12",
    sport_id: "6",
  },
  {
    key: "13",
    text: "13",
    sport_id: "6",
  },
  {
    key: "14",
    text: "14",
    sport_id: "6",
  },
  {
    key: "15",
    text: "15",
    sport_id: "6",
  },
  {
    key: "16",
    text: "16+",
    sport_id: "6",
  },
  {
    key: "17",
    text: "17+",
    sport_id: "6",
  },
  {
    key: "6",
    text: "6",
    sport_id: "7",
  },
  {
    key: "7",
    text: "7",
    sport_id: "7",
  },
  {
    key: "8",
    text: "8",
    sport_id: "7",
  },
  {
    key: "9",
    text: "9",
    sport_id: "7",
  },
  {
    key: "10",
    text: "10",
    sport_id: "7",
  },
  {
    key: "11",
    text: "11",
    sport_id: "7",
  },
  {
    key: "12",
    text: "12",
    sport_id: "7",
  },
  {
    key: "13",
    text: "13",
    sport_id: "7",
  },
  {
    key: "14",
    text: "14",
    sport_id: "7",
  },
  {
    key: "15",
    text: "15+",
    sport_id: "7",
  },
  {
    key: "6",
    text: "6+",
    sport_id: "8",
  },
  {
    key: "15",
    text: "15+",
    sport_id: "9",
  },
];

export function getMatchTypesBySport(sport_id) {
  return match_types.filter((type) => type.sport_id == sport_id);
}

export default match_types;
