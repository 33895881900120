<template>
    <div>
        <div class="max-w-lg mx-auto px-4 md:px-6 pt-8">
            <TinyNavigation :prev="{name: 'match.show', params: {id}}" />
            <h1 class="text-white font-semibold text-2xl pt-8 whitespace-pre-line" v-text="t('edit_match_params')" />
            <form @submit.prevent="handleSubmit" class="mt-5">
                <div class="grid grid-cols-1 gap-4">
                    <SelectInput v-model:value="form.position"  v-model:errors="errors.position" :options="position_options" :label="t('position_label')" />
                    <SelectInput v-model:value="form.age" :options="match_types" v-model:errors="errors.age" :label="t('age_label')" />

                    <template v-for="question in match_questions" :key="question.id">
                        <template v-if="question.type === `string` || question.type === `integer`">
                            <TextInput 
                                v-model:value="question.answer"
                                :alt-styling="true" 
                                :label="question.question" 
                                :placeholder="question.question" 
                            />
                        </template>
                        <template v-else-if="question.type === `text`">
                            <TextAreaInput 
                                v-model:value="question.answer" 
                                :label="question.question" 
                                :placeholder="question.hint" 
                            />
                        </template>

                        <div v-else class="text-white">
                            Unknown question type "{{ question.type }}"
                        </div>
                    </template>
                </div>
                
                <div class="py-8">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="button w-full">
                            {{ t('edit') }}
                        </button>
                    </span>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import { getMatch, updateMatch } from "@/helpers/api/MatchHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import { indexPositions } from "@/helpers/api/SportHelper";
import { getMatchQuestions } from "@/helpers/api/MatchQuestionHelper"
import TinyNavigation from "@/components/TinyNavigation";
import messages from "@/helpers/storage/MessageHelper";
import SelectInput from "@/components/forms/SelectInput";
import TextInput from "@/components/forms/TextInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import { getMatchTypesBySport } from "@/helpers/constants/match_types"
import useI18n from "@/modules/globalI18n";

export default {
    name: "MatchEdit",
    props: ['id'],
    components: { 
        TinyNavigation,
        TextInput,
        TextAreaInput,
        SelectInput,
    },
    setup() {
        return useI18n({
            viewPrefix: "MatchEdit"
        });
    },
    created(){
        this.getMatch();
        this.getPositions();
        this.getDynamicQuestions();
        
        const user = this.$store.getters['getUser'];
        // set match_types
        this.match_types = getMatchTypesBySport(user.sport_id);
    },
    methods:{
        getMatch() {
            return getMatch(this.id)
                .then(({ match }) => {
                    this.match = match;
                })
                .catch(err => {
                    if (err.status === 404 || err.status === 403){
                        this.match_not_found = true;
                    } else {
                        messages.error(this.t('messages.something_went_wrong', {error: err.message}));
                    }
                });
        },
        getPositions() {
            return indexPositions()
                .then(sport_positions => {
                    this.position_options = sport_positions.map(pos => {return {key: pos.abbreviation, text: pos.name}});
                });
        },
        async getDynamicQuestions() {
            this.match_questions = await getMatchQuestions();
        },
        rehydrateForm() {
            this.form.position = this.match?.position ?? "";
            this.form.age = this.match?.age ?? "";
        },
        rehydrateQuestions() {
            if(!this.match?.questions) {
                return;
            }

            for(const question of this.match_questions) {
                const { key } = question;

                if (key in this.match.questions){
                    question.answer = this.match.questions[key];
                }
            }
        },
        handleSubmit() {
            startSpinner();

            const keyAnswerEntries = Object.values(this.match_questions).filter(question => question.answer).map(({key, answer}) => [key, answer]);

            const answers = Object.fromEntries(keyAnswerEntries);
            
            const form = {...this.form, questions: JSON.stringify(answers)}

            updateMatch(form, this.id)
                .then(({ match }) => {
                    this.match = match
                    messages.success(this.t('messages.game_saved'))
                    this.$router.push({ name: "match.show", params: { id: match.id } })
                })
                .catch(err => this.errors = err.errors)
                .finally(() => stopSpinner())
        }
    },
    watch: {
        match() {
            this.rehydrateForm();
            this.rehydrateQuestions();
        },
        match_questions() {
            this.rehydrateForm();
            this.rehydrateQuestions();
        }
    },
    data(){
        return {
            form: {
                position: '',
                age: "",
            },
            errors: {
                position: [],
                age: [],
                questions: [],
            },
            match: undefined,
            position_options: [],
            match_types: [],
            match_questions: [],
        };
    },
}
</script>