<template>
    <div>
        <label v-if="label" :for="label" class="block text-xs sm:text-sm font-medium leading-5 text-gray-100">{{ label }}</label>
        <div class="mt-2 w-full rounded-md shadow-sm">
            <select ref="input" name="" id="label" @change="updateValue()" class="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                <option value="" disabled :selected="!value">{{ t('choose') }}</option>
                <option v-for="option in options" :selected="option.key == value" :key="option.key" :value="option.key">{{ option.text }}</option>
            </select>

            <div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600 font-medium">{{ error }}</p>
        </template>
    </div>
</template>

<script>
import useI18n from "@/modules/globalI18n";

export default {
    name: "SelectInput",
    setup() {
        return useI18n({
            componentPrefix: "SelectInput"
        });
    },
    props: ['value', 'errors', 'label', 'password', 'placeholder', 'options'],
    computed:{
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
    },
    methods:{
        updateValue(){
            this.$emit('update:value', this.$refs.input.value);
            this.clearErrors();
        },
        clearErrors(){
            this.$emit('update:errors', []);
        }
    }
}
</script>

<style scoped>
.form-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-repeat: no-repeat;
  background-color: #000;
  border-color: #eeeeee;
  border-width: 1px;
  border-radius: .375rem;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  background-position: right .5rem center;
  background-size:1.5em 1.5em;
  color: #fff;
}

.form-select::-ms-expand {
  color: #9fa6b2;
  border:none
}

@media (max-width: 640px) {
  .form-select {
    padding: 0.75rem;
    font-size: 0.75rem;
  }
}
</style>
