import axios from 'axios';

const index_sport_path = "v1/trainee/sport/"; // get
const index_sport_positions_path =  "v1/trainee/position/" // + {sport?} // get

function indexSport()
{
    return new Promise((resolve, reject) =>{
        axios.get(index_sport_path)
            .then((response)=>{
                resolve(response.data.sports);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function indexPositions(sport_id = null)
{
    let url = index_sport_positions_path;

    if (sport_id){
        url = index_sport_positions_path + sport_id;
    } 
    
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then((response)=>{
                resolve(response.data.sport.positions);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

export { indexSport, indexPositions };